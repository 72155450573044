#preview {
    margin-top: -50px;
}

.previewcustomCard_cont {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    place-items: center;
    column-gap: 20px;
    margin-top: 40px;
}

.previewcustomCard_cont:first-of-type {
    margin-top: 0px;
}

.previewCard_cont_schoolHeader {
    font-size: 1.5rem;
    color: #000;
    margin-top: 30px;
}

*,
*:before,
*:after{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
input[type="date"]{
    background-color: #4963f5;
    padding: 12px;
    color: #ffffff;
    font-size: 14px;
    font-weight: bold;
    border: none;
    outline: none;
    border-radius: 5px;
}
::-webkit-calendar-picker-indicator{
    background-color: #ffffff;
    padding: 5px;
    cursor: pointer;
    border-radius: 3px;
}
