.standardPreviewCard {
    box-shadow: none !important;
    /* margin-bottom: 15px !important; */
    background-color: #303841 !important;
    padding: 7px !important;
    border-radius: 25px !important;
    flex: 1;
}

/* .standardPreviewCard:last-of-type {
    margin-bottom: 0 !important;
} */

.standardPreviewCard img {
    object-fit: cover;
    object-position: top;
}