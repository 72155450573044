/* Dialog */
.users_addDialog_title {
    font-size: 18px !important;
    font-weight: 600 !important;
    padding-bottom: 0 !important;
    text-align: center;
}

.users_addDialog .MuiDialogContent-root {
    padding-bottom: 0;
}