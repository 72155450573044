.standardpageWrapper_header {
    background-color: #4b49ac;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 10px;
    /* border-radius: 15px; */
    /* margin-bottom: -30px; */
    position: relative;
}

.standardpageWrapper_header>h1 {
    color: #fff;
    cursor: default;
    text-transform: capitalize;
    font-size: 32px;
}

.standardpageWrapper_headerBtnCont {
    display: flex;
    align-items: center;
}

.standardpageWrapper_headerBtnCont>.MuiButton-root,
.standardpageWrapper_headerBtnCont>.MuiTextField-root {
    margin-left: 12px;
}

.standardpageWrapper_headerBtnCont>.MuiButton-root:first-of-type,
.standardpageWrapper_headerBtnCont>.MuiTextField-root:first-of-type {
    margin-left: 0;
}

.standardpageWrapper_dataCont {
    background-color: #fff;
    border-radius: 15px;
    padding: 10px;
    padding-top: 40px;
    margin: 0 5px;
}