#login {
    height: calc(100vh - 40px);
    overflow: hidden;
    display: grid;
    place-items: center;
}

.login_form {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    max-width: 500px;
    margin: auto;    
}