#top2 {
  /* border: 2px solid black; */
  width: 20%;
}
#box {
  /* overflow-y: scroll; */
  /* height:90vh; */
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 10px;
  /* background-color: rgb(255, 250, 148); */
  /* padding-top: 320px; */
  /* border: 2px solid black; */
  background: #e5f0fa;  /* fallback for old browsers */

}
#box1 {
  /* height: 20vh; */
  display: flex;
  flex-direction: row;
  /* border: 2px solid black; */
}
.card {
  width: 50%;
  margin: 10px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
    background-color: aliceblue !important;
}

#details {
  /* border: 0.3px solid rgb(194, 194, 194); */
  /* height: 50vh; */
  margin: 10px;
  padding: 15px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px !important;
  background-color: aliceblue;
}

.cardwithshadow {
  box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px !important;
  border-radius: 10px !important;
}

.cardwithshadow2 {
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px !important;
  border-radius: 10px !important;
}
#canvas {
  border: 20 solid black;
  /* background-image: url("https://images.template.net/3585/Free-Basic-Blank-ID-Card-Templates-1x.jpg"); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 80vh;
  width: 300px;
}

#canvas2 {
  border: 20 solid black;
  background-image: url("../../img/back.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 80vh;
  width: 300px;
}

#top2 {
  /* border: 2px solid black; */
  width: 20%;
  height: 100vh;
}
#box1 {
  /* height: 20vh; */
  display: flex;
  flex-direction: row;
  /* border: 2px solid black; */
}

#details {
  margin: 10px;
  padding: 10px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px !important;
}

.cardwithshadow {
  box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px !important;
  border-radius: 10px !important;
}

.cardwithshadow2 {
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px !important;
  border-radius: 10px !important;
}
#canvas {
  border: 20 solid black;
  /* background-image: url("https://images.template.net/3585/Free-Basic-Blank-ID-Card-Templates-1x.jpg"); */
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
 
  /* object-fit: fill; */
}



.temp {
  display: flex;
  min-height: 50vh;
}


.row1 {
  height: 10vh !important;
}

.row2 {
  height: 90vh;
}

.container {
  display: flex;
  flex-direction: column;
  padding-top: 0px;
  padding-bottom: 0px;
 padding: 0px 20px;
}