.download_by_excel_cont {
    padding: 10px 0;
    /* display: grid; */
    grid-template-columns: repeat(2, 1fr);
    place-items: center;
    column-gap: 20px;
    row-gap: 20px;
}

.download_by_excel_csvBtn>a {
    text-decoration: none !important;
    color: #fff !important;
}