#app {
  display: flex;
}

.app_routesCont {
  flex: 1;
  /* padding: 15px 10px; */
  /* height: calc(100vh - 30px); */
  height: 100vh;
  overflow: hidden;
  overflow-y: auto;
  background-color: #f5f7ff;
}