#sidemenu {
    -moz-box-shadow: -3px 0 5px 0 #555;
    -webkit-box-shadow: -3px 0 5px 0 #555;
    box-shadow: -3px 0 5px 0 #555;
    height: 100vh;
    width: 250px;
    overflow: hidden;
    overflow-y: auto;
}

.sidemenu_cont {
    padding: 20px;
}

.sidemenu_sec1 {
    margin-top: 25px;
}

.sidemenu_btn {
    font-size: 16px;
    font-weight: 500;
    border-radius: 10px;
    padding: 12px 10px;
    margin-bottom: 12px;
    transition: all 0.2s ease-in;
    cursor: pointer;
    text-transform: capitalize;
}

.sidemenu_btn:hover {
    background-color: #f5f7ff;
}

.sidemenu_btn_active {
    background-color: #9b9ae2;
    color: #fff;
}

.sidemenu_btn_active:hover {
    background-color: #7978e9;
}

.sidemenu_btn:last-of-type {
    margin-bottom: 0;
}

.sidemenu_logo {
    width: 60%;
    display: inline-block;
}

.logo_container{
    text-align: center;
    margin-bottom: 30px;
}