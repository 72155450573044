.rsuite_actions_btnCont {
    display: flex;
    max-width: max-content;
    margin: auto;
}

.rsuite_actions_btnCont>div {
    margin-right: 10px;
}

.rsuite_actions_btnCont>div:last-of-type {
    margin-right: 0;
}