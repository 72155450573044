.custom_muiSelect_input .MuiInputBase-root {
    background-color: #fff !important;
}

.custom_muiSelect_input .MuiInputBase-root:hover {
    opacity: 0.95 !important;
}

.custom_muiSelect_input .MuiInputLabel-root.Mui-focused {
    color: #000;
}

.custom_muiSelect_input .MuiInputBase-root::before,
.custom_muiSelect_input .MuiInputBase-root::after {
    border: none !important;
}